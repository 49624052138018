import { Flex } from "../../components/Flex";
import { H1 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { Image } from "../../components/Image";
import tillerVerifySvg from "../../assets/verify-tiller.svg";
import loginSvg from "../../assets/login.svg";
import { Box } from "../../components/Box";
import { Link } from "../../components/Link";
import { Button } from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../routes";
import { end } from "@popperjs/core";

const RecoverAccount = () => {
  const navigate = useNavigate();
  return (
    <Flex height="100vh">
      <Flex width="900px" flexDirection="column" p="60px" overflowY="auto">
        <Image
          src={tillerVerifySvg}
          alt="tiller verify logo"
          height="200px"
          width="160px"
        />
        <Box width={"80%"}>
          <H1>Recover Your Account</H1>
          <Paragraph my={4}>
            It looks like you don’t have access to your authenticator app. Don’t
            worry—choose one of the options below to recover your account and
            get back in.
          </Paragraph>

          <Paragraph my={4}>
            1. Use one of the single-use recovery codes you saved when setting
            up 2FA.
          </Paragraph>

          <Flex justifyContent={end}>
            <Button
              onClick={() =>
                navigate(ROUTE.USE_RECOVERY_CODE, { replace: true })
              }
              variant="text"
            >
              Use Recovery Code
            </Button>
          </Flex>

          <Paragraph my={4}>
            2. Verify your identity by receiving a one time password at your
            registered email address.
          </Paragraph>

          <Flex justifyContent={end}>
            <Button
              onClick={() =>
                navigate(ROUTE.USE_RECOVERY_EMAIL, { replace: true })
              }
              variant="text"
            >
              Verify via Email
            </Button>
          </Flex>

          <Paragraph my={4}>
            If you don’t have recovery codes or access to your registered email
            address, please contact our support team:{" "}
            <Link
              fontSize="1"
              href="mailto:support@tillertech.com"
              target="_blank"
            >
              support@tillertech.com
            </Link>
          </Paragraph>
        </Box>
      </Flex>
      <Flex
        bg={"background"}
        display={{ _: "none", md: "flex" }}
        height="100vh"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={loginSvg}
          alt="login image"
          display="block"
          width="55%"
          height="60%"
        />
      </Flex>
    </Flex>
  );
};

export default RecoverAccount;
