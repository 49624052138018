import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { AdditionalDocuments } from "../types/AdditionalDocumentsType";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

const fetch = (authHeader: string, individualId: number) => () =>
  axios.get<AdditionalDocuments>(
    `${env.verifyServiceUrl}individual/individual_v2/${individualId}/documents/?type=all`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useAdditionalDocuments = (id) => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  return useQuery(["additionalDocuments", id], fetch(authHeader, id), {
    retry: false,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
      if(err.request.status === 401) {
        navigate(ROUTE.LOGOUT);
      }
    }
  });
};
