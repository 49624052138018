import { Flex } from "../components/Flex";
import { Image } from "../components/Image";
import signup from "../assets/sign-up.svg";
import tillerVerifySvg from "../assets/verify-tiller.svg";
import { H2, H3 } from "../components/Heading";
import { Paragraph } from "../components/Paragraph";
import { Box } from "../components/Box";
import lock from "../assets/lock.svg";
import { Button } from "../components/Button";
import { Enable2FAModal } from "../components/Modals/2FA/Enable2FAModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";
import download from "../assets/2FA/download-arrow.svg";
import otp from "../assets/2FA/otp.svg";
import password from "../assets/2FA/password.svg";
import { useDisable2FA } from "../data/useDisable2FA";
import { showError } from "../utils/error-handling";

const LoginSetup2FA = () => {
  const navigate = useNavigate();
  const [isEnable2FAModalOpen, setIsEnable2FAModalOpen] = useState(false);
  const { mutate: disable2FA } = useDisable2FA();

  const handleClose = () => {
    setIsEnable2FAModalOpen(false);
  };
  const handleSuccessClose = () => {
    setIsEnable2FAModalOpen(false);
    navigate(ROUTE.APPLICATIONS);
  };

  const handleSkip = () => {
    disable2FA(
      { skip: true },
      {
        onSuccess: (res) => {
          navigate(ROUTE.APPLICATIONS);
        },
        onError: (err: any) => {
          showError(
            err,
            "A problem occurred while disabling 2 Factor Authentication."
          );
        },
      }
    );
  };

  const handleSetup = () => {
    setIsEnable2FAModalOpen(true);
  };
  return (
    <Flex height="100vh">
      <Flex
        width="900px"
        justifyContent="space-between"
        flexDirection="column"
        p="60px"
        overflowY="auto"
      >
        <Image
          src={tillerVerifySvg}
          alt="tiller verify logo"
          height="200px"
          width="160px"
        />

        <>
          <H2>Set Up 2 Factor Authentication</H2>
          <Paragraph>
            Two-Factor Authentication (2FA) is an added layer of security for
            your account.
          </Paragraph>

          <Paragraph fontWeight={600}>How it Works</Paragraph>

          <Flex
            justifyContent={"center"}
            backgroundColor={"gray.100"}
            borderRadius={"50%"}
            height={60}
            width={60}
          >
            <Image
              src={download}
              alt="Download Icon"
              height="auto"
              width="22px"
            />
          </Flex>
          <Paragraph>
            First, you'll need to download an authenticator app on your mobile
            device such as Microsoft or Google Authenticator.
          </Paragraph>

          <Flex
            justifyContent={"center"}
            backgroundColor={"gray.100"}
            borderRadius={"50%"}
            height={60}
            width={60}
          >
            <Image src={otp} alt="Download Icon" height="auto" width="22px" />
          </Flex>
          <Paragraph>
            After installation, the app will generate a temporary OTP every 30
            seconds.
          </Paragraph>
          <Flex
            justifyContent={"center"}
            backgroundColor={"gray.100"}
            borderRadius={"50%"}
            height={60}
            width={60}
          >
            <Image
              src={password}
              alt="Download Icon"
              height="auto"
              width="22px"
            />
          </Flex>
          <Paragraph>
            During login, input your password, followed by the current OTP from
            the app.
          </Paragraph>

          <Button my={3} variant="secondary" onClick={handleSkip}>
            Skip for now
          </Button>
          <Button variant="primary" onClick={handleSetup}>
            Set up
          </Button>

          <Enable2FAModal
            isOpen={isEnable2FAModalOpen}
            onRequestClose={handleClose}
            initialNextClicked={true}
            onSuccessClose={handleSuccessClose}
          />
        </>
      </Flex>
      <Flex
        bg={"background"}
        display={{ _: "none", md: "flex" }}
        height="100vh"
        width="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
      >
        <Box width="55%">
          <Image src={lock} height="80px" width="100px" mb="60px" mt="2" />
          <H3 color="darkBlue">
            Unlock Verify’s full features. What are you waiting for? Let’s get
            started today!
          </H3>
          <Paragraph color="gray.50" fontSize="3">
            Create your account today and unlock all Verify’s features.
          </Paragraph>
        </Box>
        <Image
          src={signup}
          alt="login image"
          display="block"
          width="55%"
          height="60%"
        />
      </Flex>
    </Flex>
  );
};

export default LoginSetup2FA;
