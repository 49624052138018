import axios from "axios";
import { env } from "../utils/env";
import { useAuth } from "../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { SOFRiskLevel } from "../types/SOFRiskLevel";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

interface SOFRiskLevelResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: SOFRiskLevel[];
}

const fetch = (authHeader: string) => () =>
  axios.get<SOFRiskLevelResponse>(
    `${env.verifyServiceUrl}individual/sof_risk_level/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useSOFRiskLevel = () => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  return useQuery(["sofRiskLevel"], fetch(authHeader), {
    retry: false,
    select: (response) => response.data.results,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
      if(err.request.status === 401) {
        navigate(ROUTE.LOGOUT);
      }
    }
    ,
  });
};
