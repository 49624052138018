import { Field, Form, Formik } from "formik";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { DatePicker } from "../../components/DatePicker";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { Input } from "../../components/Input";
import { Paragraph } from "../../components/Paragraph";
import Select, { Option } from "../../components/Select";
import { newMonitoringFormValidation } from "../../utils/validationSchema";
import { MIN_AGE_APPLICATION } from "../../utils/constants";
import { useCommonTitles } from "../../data/useCommonTitles";
import { useState } from "react";
import { Modal } from "../../components/Modal";
import { NewMonitoringRecordFrame } from "./NewMonitoringRecordFrame";
import { Label } from "../../components/Label";
import { Box } from "../../components/Box";
import styled from "styled-components";

const rowProps: any = {
  flexWrap: "wrap",
  gap: "16",
  my: "4",
};

const inputProps = {
  minWidth: 200,
  flex: 1,
};

const genderOptions = [
  { value: "female", label: "Female" },
  { value: "male", label: "Male" },
  { value: "other", label: "Other" },
];

const MandateTypeBubble = ({ title, content, children }: any) => (
  <Box borderRadius={0} bg="#e3e7ee" p={3} mb={2}>
    <Paragraph mt="0px" fontWeight="bold">
      {title}
    </Paragraph>
    {content ? <Paragraph mb="0px">{content}</Paragraph> : children}
  </Box>
);

const StyledUl = styled.ul`
  ${({ theme: { fontSizes, fontFamily } }) => `     
      font-family: ${fontFamily};
      font-size: ${fontSizes[2]};   
      padding-left: 12px; 
      margin-bottom: 0px;
    `}
`;

export const StyledLi = styled.li`
  ${({ theme: { space, colors } }) => `
        margin-left: ${space[2]};

        ::marker{
        color: ${colors.gray[40]};
        }
  `}
`;

const Header = () => {
  return (
    <>
      <H2>Add Individual</H2>
      <Paragraph>
        Please add the individual you would like to create a monitoring record
        for.
      </Paragraph>
    </>
  );
};

export const NewIndividual = () => {
  const [doubleClick, setDoubleClick] = useState(false);
  const navigate = useNavigate();

  const [isNoDOBModalOpen, setIsNoDOBModalOpen] = useState(false);
  const [formData, setFormData] = useState(null);

  const { data } = useCommonTitles();

  const titleOptions = data?.data.map(({ name }) => ({
    label: name,
    value: name,
  })) as Option[];

  const pepOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  const handleCreateIndividual = (form: any) => {
    setFormData(form);
    if (form.dateOfBirth) {
      handleSubmit(form);
    } else {
      setIsNoDOBModalOpen(true);
    }
  };

  const handleNext = () => {
    handleSubmit(formData);
  };

  const handleSubmit = (form) => {
    setDoubleClick(true);

    navigate(`/monitoring/new/confirm`, {
      state: { form },
    });
  };

  return (
    <>
      <NewMonitoringRecordFrame step="individual">
        <Formik
          initialValues={{
            title: "",
            firstName: "",
            middleName: "",
            lastName: "",
            dateOfBirth: "",
            gender: "",
            pep_and_sanction_required: "",
            adverse_media_required: "",
          }}
          validationSchema={newMonitoringFormValidation}
          onSubmit={handleCreateIndividual}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit} style={{ width: "510px" }}>
              <Header />
              <Flex {...rowProps}>
                <div style={{ width: "48%" }}>
                  <Select
                    flex={1}
                    onChange={(option: any) =>
                      props.setFieldValue("title", option.value)
                    }
                    value={titleOptions?.find(
                      ({ value }) => value === props.values.title
                    )}
                    options={titleOptions}
                    placeholder="Select title"
                    mt="2"
                    label="Title"
                    name="title"
                    isRequired
                    hasError={Boolean(props.errors.title)}
                    errorMessage={props.errors.title as string}
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <Select
                    flex={1}
                    onChange={(option: any) =>
                      props.setFieldValue("gender", option.value)
                    }
                    value={genderOptions?.find(
                      ({ value }) => value === props.values.gender
                    )}
                    options={genderOptions}
                    placeholder="Select gender"
                    mt="2"
                    label="Gender"
                    name="gender"
                    isRequired
                    hasError={Boolean(props.errors.gender)}
                    errorMessage={props.errors.gender as string}
                  />
                </div>
              </Flex>
              <Flex {...rowProps}>
                <Input
                  {...inputProps}
                  onChange={props.handleChange}
                  value={props.values.firstName}
                  placeholder="Enter first name"
                  mt="2"
                  label="First name"
                  name="firstName"
                  hasError={Boolean(props.errors.firstName)}
                  errorMessage={props.errors.firstName as string}
                  isRequired
                />
                <Input
                  {...inputProps}
                  onChange={props.handleChange}
                  value={props.values.middleName}
                  placeholder="Enter middle(s) name"
                  mt="2"
                  label="Middle name(s)"
                  name="middleName"
                  hasError={Boolean(props.errors.middleName)}
                  errorMessage={props.errors.middleName as string}
                />
              </Flex>
              <Flex {...rowProps}>
                <Input
                  {...inputProps}
                  onChange={props.handleChange}
                  value={props.values.lastName}
                  placeholder="Enter last name"
                  mt="2"
                  label="Last name"
                  name="lastName"
                  isRequired
                  hasError={Boolean(props.errors.lastName)}
                  errorMessage={props.errors.lastName as string}
                />
                <DatePicker
                  value={props.values.dateOfBirth as any}
                  onChange={(date: any) => {
                    props.setFieldValue("dateOfBirth", date);
                  }}
                  mt="2"
                  maxDate={DateTime.now()
                    .minus({ years: MIN_AGE_APPLICATION })
                    .toJSDate()}
                  label="Date of birth"
                  name="dateOfBirth"
                  hasError={Boolean(props.errors.dateOfBirth)}
                  errorMessage={props.errors.dateOfBirth as string}
                  {...inputProps}
                />
              </Flex>

              <Paragraph>
                Please select the activities you would like to monitor.
              </Paragraph>

              <Flex
                {...rowProps}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Label>PEP & sanctions monitoring</Label>

                <Field name="pep_and_sanction_required">
                  {({ field, meta }) => (
                    <Select
                      {...field}
                      onBlur={() => props.setFieldTouched(field.name)} // Trigger validation on blur
                      onChange={(option: any) =>
                        props.setFieldValue(
                          "pep_and_sanction_required",
                          option.value
                        )
                      }
                      placeholder="Select value"
                      name="pep_and_sanction_required"
                      value={pepOptions?.find(
                        ({ value }) =>
                          value === props.values.pep_and_sanction_required
                      )}
                      options={pepOptions}
                      hasError={
                        meta.touched &&
                        (props.errors[field.name] || !!meta.error)
                      }
                      errorMessage={
                        meta.touched
                          ? props.errors[field.name] || meta.error
                          : ""
                      }
                      width={"50%"}
                      isRequired
                    />
                  )}
                </Field>
              </Flex>

              <Flex
                {...rowProps}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Label>Adverse media monitoring</Label>

                <Field name="adverse_media_required">
                  {({ field, meta }) => (
                    <Select
                      {...field}
                      onBlur={() => props.setFieldTouched(field.name)}
                      onChange={(option: any) =>
                        props.setFieldValue(
                          "adverse_media_required",
                          option.value
                        )
                      }
                      placeholder="Select value"
                      name="adverse_media_required"
                      value={pepOptions?.find(
                        ({ value }) =>
                          value === props.values.adverse_media_required
                      )}
                      options={pepOptions}
                      isRequired
                      hasError={
                        meta.touched &&
                        (props.errors[field.name] || !!meta.error)
                      }
                      errorMessage={
                        meta.touched
                          ? props.errors[field.name] || meta.error
                          : ""
                      }
                      width={"50%"}
                    />
                  )}
                </Field>
              </Flex>

              <Flex justifyContent="flex-end">
                {/* <Button
                size="large"
                mt="2"
                mb="0"
                variant="secondary"
                onClick={() =>
                  navigate(`/applications/${mandateId}/individuals`)
                }
              >
                Back
              </Button> */}
                <Button
                  size="large"
                  mt="2"
                  mb="2"
                  type="submit"
                  isDisabled={(props.dirty && !props.isValid) || doubleClick}
                >
                  Next
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>

        <Box
          display={{
            _: "none",
            xl: "block",
          }}
          position="absolute"
          right={4}
          top="205px"
          width="325px"
        >
          <Paragraph fontWeight="bold">Monitoring</Paragraph>
          <MandateTypeBubble
            title="Description"
            content="A monitoring record will be created for this individual. You will recieve a notification for any new PEP & sanctions, adverse media events. "
          />
          <MandateTypeBubble title="Events">
            <StyledUl>
              <StyledLi>PEP & sanctions screening</StyledLi>
              <Paragraph my={0} fontSize={1} color={"gray.60"}>
                A new potential PEP or sanctions result against the individual.
              </Paragraph>
              <StyledLi>Adverse media check</StyledLi>
              <Paragraph my={0} fontSize={1} color={"gray.60"}>
                A new potential adverse media match against the individual.
              </Paragraph>
            </StyledUl>
          </MandateTypeBubble>
        </Box>

        <Modal
          isOpen={isNoDOBModalOpen}
          onClose={() => setIsNoDOBModalOpen(false)}
          title="No date of birth"
          content="Although date of birth is not required, it improves the accuracy of the results. We recommend providing a date of birth before proceeding."
          onModalConfirmClick={handleNext}
          secondaryButtonText="Cancel"
          primaryButtonText="Continue anyway"
          primaryButtonVariant="danger"
          hasSecondaryButton
          width="450px"
        />
      </NewMonitoringRecordFrame>
    </>
  );
};
