import styled from "styled-components";
import {
  space,
  color,
  typography,
  SpaceProps,
  TypographyProps,
  ColorProps,
} from "styled-system";

type ParagraphProps = SpaceProps & TypographyProps & ColorProps;

export const Link = styled.a<ParagraphProps>`
  font-family: "Inter", sans-serif;
  ${({ theme: { fontSizes, colors } }) => `
    font-size: ${fontSizes[2]};
    color: ${colors.blue.primary};
    cursor: pointer;
    word-break: break-all;
  `}

  ${typography}
  ${color}
  ${space}
`;
