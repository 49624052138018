import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { Flex } from "../../components/Flex";
import { H3 } from "../../components/Heading";
import { Loader } from "../../components/Loader";
import { useAuth } from "../../context/AuthContext";
import { Paragraph } from "../../components/Paragraph";
import { useState } from "react";
import { Enable2FAModal } from "../../components/Modals/2FA/Enable2FAModal";
import { Manage2FAModal } from "../../components/Modals/2FA/Manage2FAModal";
import { queryClient } from "../../queryClient";

export const Settings2FactorEnableDisable = () => {
  const [isEnable2FAModalOpen, setIsEnable2FAModalOpen] = useState(false);
  const [isManage2FAModalOpen, setIsManage2FAModalOpen] = useState(false);
  const { userDetails, isLoadingUserDetails } = useAuth();

  const handleClose = () => {
    setIsEnable2FAModalOpen(false);
  };

  const handleSuccessClose = () => {
    setIsEnable2FAModalOpen(false);
    queryClient.invalidateQueries(["userDetails"]);
  };

  const handleEnable = () => {
    setIsEnable2FAModalOpen(true);
  };
  const handleManage = () => {
    setIsManage2FAModalOpen(true);
    queryClient.invalidateQueries(["userDetails"]);
  };

  return (
    <Box bg="white" borderRadius={0} p={5} maxWidth={900}>
      <H3>2 Factor Authentication</H3>

      {isLoadingUserDetails && (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      )}

      {!isLoadingUserDetails && userDetails?.two_factor_enabled ? (
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Paragraph>Disable 2 Factor Authentication</Paragraph>
          <Button type="button" variant="primary" onClick={handleManage}>
            Manage
          </Button>
        </Flex>
      ) : (
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Paragraph>Enable 2 Factor Authentication</Paragraph>
          <Button type="button" variant="primary" onClick={handleEnable}>
            Enable
          </Button>
        </Flex>
      )}

      <Enable2FAModal
        isOpen={isEnable2FAModalOpen}
        onRequestClose={handleClose}
        onSuccessClose={handleSuccessClose}
      />
      <Manage2FAModal
        isOpen={isManage2FAModalOpen}
        onRequestClose={() => setIsManage2FAModalOpen(false)}
      />
    </Box>
  );
};
