import { Formik, Form } from "formik";
import { useCreateTenantUser } from "../../data/useCreateTenantUser";
import { useOtherUserDetails } from "../../data/useOtherUserDetails";
import { usePermissionGroups } from "../../data/usePermissionGroups";
import { useUpdateUserDetails } from "../../data/useUpdateUserDetails";
import { queryClient } from "../../queryClient";
import { showError } from "../../utils/error-handling";
import { notify } from "../../utils/notify";
import { Box } from "../Box";
import { Button } from "../Button";
import { Flex } from "../Flex";
import { FormRow } from "../Form";
import { Input, InputProps } from "../Input";
import { Loader } from "../Loader";
import { Modal } from "../Modal";
import { Paragraph } from "../Paragraph";
import Select, { Option } from "../Select";
import { addUserValidation } from "../../utils/validationSchema";

export const AddEditUserModal = (props: any) => {
  const { isOpen, onRequestClose, userId } = props;
  const mode = typeof userId === "number" ? "edit" : "create";

  const { mutate: createUser, isLoading: isLoadingCreate } =
    useCreateTenantUser();
  const { mutate: updateUser, isLoading: isLoadingUpdate } =
    useUpdateUserDetails();
  const { data: user, isLoading: isLoadingUserDetails } = useOtherUserDetails(
    userId,
    mode === "edit"
  );

  const { data: permissions } = usePermissionGroups();

  const permissionGroupOptions = permissions?.data?.results?.map(
    ({ id, name }) => ({
      label: name,
      value: id,
    })
  ) as Option[];

  const isLoadingUser = mode === "edit" ? isLoadingUserDetails : false;
  const isLoadingAction = isLoadingCreate || isLoadingUpdate;

  const handleSubmit = (data) => {
    const payload: any = {
      first_name: data.firstName.trim(),
      last_name: data.lastName.trim(),
      job_title: data.jobTitle.trim(),
      groups: [data.groups],
    };

    if (mode !== "edit") {
      payload.email = data.email;
    }

    const onComplete = () => {
      queryClient.invalidateQueries(["users"]);
      onRequestClose();
    };

    if (mode === "edit") {
      updateUser(
        {
          id: userId,
          ...payload,
        },
        {
          onSuccess: (res) => {
            if (res?.status?.toString().startsWith("2"))
              notify("User Updated", {
                type: "success",
              });
            onComplete();
            queryClient.invalidateQueries(["userDetails", userId]);
          },
          onError: (err: any) => {
            showError(err, "A problem occurred while updating the user.");
            // displayError(
            //   collapseErrors(extractErrors(err)) ||
            //     "A problem occurred while updating the user."
            // );
            // logError(err);
          },
        }
      );
    } else {
      createUser(payload, {
        onSuccess: (res) => {
          if (res?.status?.toString().startsWith("2"))
            notify("User Invited", {
              type: "success",
            });
          onComplete();
        },
        onError: (err: any) => {
          showError(err, "A problem occurred while creating the user.");
          // displayError(
          //   collapseErrors(extractErrors(err)) ||
          //     "A problem occurred while creating user."
          // );
          // logError(err);
        },
      });
    }
  };

  const inputProps: Partial<InputProps> = {
    minWidth: 200,
    flex: 1,
  };

  return (
    <Modal width="700px" isOpen={isOpen} onClose={onRequestClose}>
      {isLoadingUser && (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      )}

      {!isLoadingUser && (
        <Formik
          initialValues={{
            firstName: user?.data.first_name || "",
            lastName: user?.data.last_name || "",
            email: user?.data.email || "",
            jobTitle: user?.data.job_title || "",
            groups: user?.data?.groups?.[0]?.id || "",
          }}
          validationSchema={addUserValidation}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Box overflowY="auto">
                <Paragraph fontWeight="bold" fontSize={3}>
                  {userId ? "Edit User" : "Invite User"}
                </Paragraph>
                <FormRow my={3}>
                  <Input
                    onChange={props.handleChange}
                    value={props.values.firstName}
                    placeholder="Enter your first name"
                    label="First name"
                    name="firstName"
                    {...inputProps}
                    isRequired
                    hasError={Boolean(props.errors.firstName)}
                    errorMessage={props.errors.firstName}
                  />
                  <Input
                    onChange={props.handleChange}
                    value={props.values.lastName}
                    placeholder="Enter your last name"
                    label="Last name"
                    name="lastName"
                    {...inputProps}
                    isRequired
                    hasError={Boolean(props.errors.lastName)}
                    errorMessage={props.errors.lastName}
                  />
                </FormRow>
                <FormRow my={3}>
                  <Input
                    onChange={props.handleChange}
                    value={props.values.email}
                    placeholder="Enter your email address"
                    label="Email Address"
                    name="email"
                    isDisabled={mode === "edit"}
                    {...inputProps}
                    isRequired
                    hasError={Boolean(props.errors.email)}
                    errorMessage={props.errors.email}
                  />
                  <Input
                    onChange={props.handleChange}
                    value={props.values.jobTitle}
                    placeholder="Enter your job title"
                    label="Job Title"
                    name="jobTitle"
                    {...inputProps}
                    isRequired
                    hasError={Boolean(props.errors.jobTitle)}
                    errorMessage={props.errors.jobTitle}
                  />
                </FormRow>
                <Select
                  value={
                    props.values.groups
                      ? permissionGroupOptions?.find(
                          ({ value }) => value === props.values.groups
                        )
                      : null
                  }
                  options={permissionGroupOptions}
                  onChange={(option: Option) =>
                    props.setFieldValue("groups", option.value)
                  }
                  label="Permissions"
                  isInModal
                  isRequired
                  hasError={Boolean(props.errors.groups)}
                  errorMessage={props.errors.groups}
                />
              </Box>
              <Box>
                <Button
                  type="submit"
                  size="large"
                  width="100%"
                  mt="4"
                  mb="0"
                  isDisabled={
                    !props.dirty ||
                    (props.dirty && !props.isValid) ||
                    isLoadingAction
                  }
                >
                  {userId ? "Save" : "Send Invite"}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
