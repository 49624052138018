import { Formik, Form } from "formik";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { Flex } from "../../components/Flex";
import { H2, H3 } from "../../components/Heading";
import { Input, InputProps } from "../../components/Input";
import { Loader } from "../../components/Loader";
import { showError } from "../../utils/error-handling";
import { notify } from "../../utils/notify";
import { useEffect, useState } from "react";
import { useDPODetails } from "../../data/useDPODetails";
import { useUpdateDPO } from "../../data/useUpdateDPO";
import { useDirtyForm } from "./DirtyFormContext";
import { queryClient } from "../../queryClient";
import { DPO_validation } from "../../utils/validationSchema";
import { DPO, DPOPeriod } from "../../types/DPO";
import { useCreateDPODetail } from "../../data/useCreateDPODetail";
import { Paragraph } from "../../components/Paragraph";
import RangeSlider from "../../components/RangeSlider";
import { Toggle } from "../../components/Toggle";
import { useDPOPeriodDetails } from "../../data/useDPOPeriodDetails";
import { useCreateDataRetentionDetail } from "../../data/useCreateDataRetentionDetail";
import { useUpdateDataRetentionDetails } from "../../data/useUpdateDataRetentionDetails";

const SettingsDPO = () => {
  const [doubleClick, setDoubleClick] = useState(false);

  const { data: dpoDetails, isLoading } = useDPODetails();

  const { mutate: updateDPO, isLoading: isUpdatingDPO } = useUpdateDPO();

  const { mutate: createDPODetail } = useCreateDPODetail();

  const { setFormDirty, formRef } = useDirtyForm();

  const { data: dpoPeriodDetails } = useDPOPeriodDetails();

  const { mutate: updateDataRetentionDetails, isLoading: isUpdatingDataRetention } = useUpdateDataRetentionDetails();

  const { mutate: createDataRetentionDetails } = useCreateDataRetentionDetail();

  const initialState = {
    deletion_period: 56,
    required_auto_archieve: false,
    required_delete_archieve_email: false,
  };

  const handleSubmit = (data: DPO) => {
    setDoubleClick(true);
    setFormDirty(false);

    if (dpoDetails.data.results.length > 0 && dpoDetails?.data?.results[0].id) {
      updateDPO(
        {
          id: dpoDetails?.data?.results[0].id,
          name: data.name,
          email: data.email,
          phone_number: data.phone_number,
        },
        {
          onSuccess: (res) => {
            setDoubleClick(false);
            if (res?.status?.toString().startsWith("2")) {
              notify("DPO details updated", {
                type: "success",
              });
              queryClient.invalidateQueries(["dpoDetails"]);
            }
          },
          onError: (err: any) => {
            setDoubleClick(false);
            showError(
              err,
              "A problem occurred while updating the dpo details."
            );
          },
        }
      );
    } else {
      createDPODetail(
        {
          name: data.name,
          email: data.email,
          phone_number: data.phone_number,
        },
        {
          onSuccess: (res) => {
            if (res?.status?.toString().startsWith("2")) {
              notify("DPO details created", {
                type: "success",
              });
              queryClient.invalidateQueries(["dpoDetails"]);
              setDoubleClick(false);
            }
          },
          onError: (err: any) => {
            showError(err, "A problem occurred while adding DPO details.");
            setDoubleClick(false);
          },
        }
      );
    }
  };

  const handleDataRetentionSubmit = (data: DPOPeriod) => {
    if (dpoPeriodDetails.data.results.length > 0 && dpoPeriodDetails?.data?.results[0].id) {
      updateDataRetentionDetails(
        {
          id: dpoPeriodDetails?.data?.results[0].id,
          deletion_period: data.deletion_period,
          required_auto_archieve: data.required_auto_archieve,
          required_delete_archieve_email: data.required_delete_archieve_email,
        },
        {
          onSuccess: (res) => {
            setDoubleClick(false);
            if (res?.status?.toString().startsWith("2")) {
              notify("Data Retention Period details updated", {
                type: "success",
              });
              //setDpoPeriodData(res.data);
              queryClient.invalidateQueries(["dpoPeriodDetails"]);
            }
          },
          onError: (err: any) => {
            setDoubleClick(false);
            showError(
              err,
              "A problem occurred while updating the data retention period details."
            );
          },
        }
      );
    } else {
      createDataRetentionDetails(
        {
          deletion_period: data.deletion_period,
          required_auto_archieve: data.required_auto_archieve,
          required_delete_archieve_email: data.required_delete_archieve_email,
        },
        {
          onSuccess: (res) => {
            if (res?.status?.toString().startsWith("2")) {
              notify("Data Retention Period details created", {
                type: "success",
              });
              queryClient.invalidateQueries(["dpoPeriodDetails"]);
              setDoubleClick(false);
            }
          },
          onError: (err: any) => {
            showError(err, "A problem occurred while adding Data Retention Period details.");
            setDoubleClick(false);
          },
        }
      );
    }
  }

  const inputProps: Partial<InputProps> = {
    minWidth: 200,
    flex: 1,
  };

  const rowProps: any = {
    flexWrap: "wrap",
    gap: "16",
    my: "4",
  };

  return (
    <>
      <Box bg="white" borderRadius={0} p={3} mb={3}>
        <H2>Data Protection Officer Details</H2>

        {isLoading && (
          <Flex justifyContent="center" alignItems="center" minHeight="35vh">
            <Loader />
          </Flex>
        )}

        {!isLoading && (
          <>
            <Formik
              initialValues={{
                name: dpoDetails?.data?.results[0]?.name || "",
                email: dpoDetails?.data?.results[0]?.email || "",
                phone_number: dpoDetails?.data?.results[0]?.phone_number || "",
              }}
              enableReinitialize
              validate={() => null}
              validationSchema={DPO_validation}
              onSubmit={(values, actions) => {
                handleSubmit(values);
                actions.setSubmitting(false); // Make sure to set submitting to false
              }}
              innerRef={formRef}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <Box>
                    <Flex {...rowProps}>
                      <Input
                        onChange={(e) => {
                          setFormDirty(true);
                          props.handleChange(e);
                        }}
                        value={props.values.name}
                        placeholder="Enter name"
                        label="Name"
                        name="name"
                        {...inputProps}
                        isRequired
                        hasError={Boolean(props.errors.name)}
                        errorMessage={props.errors.name}
                      />
                    </Flex>

                    <Flex {...rowProps}>
                      <Input
                        onChange={(e) => {
                          setFormDirty(true);
                          props.handleChange(e);
                        }}
                        value={props.values.email}
                        placeholder="Enter email address"
                        label="Email address"
                        name="email"
                        {...inputProps}
                        hasError={Boolean(props.errors.email)}
                        errorMessage={props.errors.email}
                      />
                      <Input
                        onChange={(e) => {
                          setFormDirty(true);
                          props.handleChange(e);
                        }}
                        value={props.values.phone_number}
                        placeholder="Enter contact number"
                        label="Contact number"
                        name="phone_number"
                        {...inputProps}
                        hasError={Boolean(props.errors.phone_number)}
                        errorMessage={props.errors.phone_number}
                      />
                    </Flex>
                  </Box>

                  <Box>
                    <Flex>
                      <Button
                        size="large"
                        mb="0"
                        type="submit"
                        isDisabled={
                          !props.dirty ||
                          (props.dirty && !props.isValid) ||
                          isUpdatingDPO ||
                          doubleClick
                        }
                      >
                        Save
                      </Button>
                    </Flex>
                  </Box>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Box>
      <Box bg="white" borderRadius={0} p={3} mb={3}>
        {isLoading && (
          <Flex justifyContent="center" alignItems="center" minHeight="35vh">
            <Loader />
          </Flex>
        )}

        {!isLoading && (
          <>
            <Formik
              initialValues={{
                deletion_period: dpoPeriodDetails?.data?.results[0]?.deletion_period || 56,
                required_auto_archieve: dpoPeriodDetails?.data?.results[0]?.required_auto_archieve || false,
                required_delete_archieve_email: dpoPeriodDetails?.data?.results[0]?.required_delete_archieve_email || false,
              }}
              enableReinitialize
              validate={() => null}
              onSubmit={(values, actions) => {
                handleDataRetentionSubmit(values);
                actions.setSubmitting(false); // Make sure to set submitting to false
              }}
              innerRef={formRef}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <Box>
                    <Flex justifyContent="space-between">
                      <H2>Data Retention</H2>
                      <Button
                        onClick={() => {
                          props.resetForm({ values: initialState });
                          props.submitForm();
                        }}
                        variant="text"
                        type="button"
                        fontWeight={"bold"}
                      >
                        Reset all
                      </Button>
                    </Flex>
                    <Paragraph width={"90%"}>Verify operates as a 'check-and-forget' system. Applications and checks are kept for up to
                      your retention period before being removed, ensuring we do not hold PII(Personally Identifiable Information)
                      longer than necessary. Customer PDFs should be saved to your records.
                    </Paragraph>
                    <H3 mt="3" mb="3">Data Retention Period</H3>

                    <H3>Delete date</H3>
                    <Flex justifyContent="space-between">
                      <Paragraph width={"50%"}>Application and Screening records will be retained for up to this number of days
                        from when they are created, after which they are removed. </Paragraph>
                      <div style={{ width: "45%" }} >
                        <RangeSlider
                          min={56}
                          max={90}
                          toleranceValue={props.values ? props.values.deletion_period : 56}
                          onChange={(e) => {
                            setFormDirty(true);
                            props.setFieldValue("deletion_period", e);
                          }}
                        />
                      </div>
                    </Flex>

                    <H3>Automatic archive</H3>
                    <Flex justifyContent="space-between">
                      <Paragraph width={"50%"}>Application and Screening records will be automatically archived 14 days before their delete date.
                        Removing them from the default view.
                      </Paragraph>
                      <Toggle
                        isChecked={props.values.required_auto_archieve}
                        onChange={(e: any) => {
                          setFormDirty(true);
                          props.setFieldValue("required_auto_archieve", e.target.checked);
                        }}
                        mt={2}
                      />
                    </Flex>

                    <H3>Delete and archive email</H3>
                    <Flex justifyContent="space-between">
                      <Paragraph width={"50%"}>This assigned user will receive an email notification three days before the archive and delete date.
                      </Paragraph>
                      <Toggle
                        isChecked={props.values.required_delete_archieve_email}
                        onChange={(e: any) => {
                          setFormDirty(true);
                          props.setFieldValue("required_delete_archieve_email", e.target.checked);
                        }}
                        mt={2}
                      />
                    </Flex>
                  </Box>
                  <Box>
                    <Flex>
                      <Button
                        size="large"
                        mb="0"
                        type="submit"
                        isDisabled={
                          !props.dirty ||
                          (props.dirty && !props.isValid) ||
                          isUpdatingDataRetention ||
                          doubleClick
                        }
                      >
                        Save
                      </Button>
                    </Flex>
                  </Box>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Box>

    </>
  );
};

export default SettingsDPO;
