import { useState } from "react";
import { useDisable2FA } from "../../../data/useDisable2FA";
import { Button } from "../../Button";
import { Flex } from "../../Flex";
import { H2 } from "../../Heading";
import { Modal } from "../../Modal";
import { Paragraph } from "../../Paragraph";
import { showError } from "../../../utils/error-handling";

export const Disable2FAModal = (props: any) => {
  const { isOpen, onRequestClose } = props;
  const [showSuccess, setShowSuccess] = useState(false);

  const { mutate: disable2FA } = useDisable2FA();

  const handleTurnOff = () => {
    disable2FA(
      { skip: false },
      {
        onSuccess: (res) => {
          setShowSuccess(true);
        },
        onError: (err: any) => {
          showError(
            err,
            "A problem occurred while disabling 2 Factor Authentication."
          );
        },
      }
    );
  };

  return (
    <Modal width="700px" isOpen={isOpen} onClose={onRequestClose}>
      {!showSuccess ? (
        <>
          <H2>Turn Off 2 Factor Authentication</H2>

          <Paragraph color={"gray.50"}>
            Are you sure you want to turn off Two-Factor Authentication (2FA)?
          </Paragraph>

          <Flex mt={4} justifyContent={"end"} gap="18">
            <Button type="button" variant="secondary" onClick={onRequestClose}>
              Cancel
            </Button>
            <Button type="button" variant="primary" onClick={handleTurnOff}>
              Turn Off
            </Button>
          </Flex>
        </>
      ) : (
        <>
          <H2>2-Factor Authentication Turned Off Successfully</H2>

          <Paragraph color={"gray.50"}>
            You have successfully turned off Two-Factor Authentication.
          </Paragraph>

          <Flex mt={4} justifyContent={"end"} gap="18">
            <Button type="button" variant="primary" onClick={onRequestClose}>
              Close
            </Button>
          </Flex>
        </>
      )}
    </Modal>
  );
};
