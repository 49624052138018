import styled from "styled-components";
import {
  space,
  color,
  typography,
  layout,
  flexbox,
  background,
  border,
  position,
  SpaceProps,
  BackgroundProps,
  LayoutProps,
  ColorProps,
  TypographyProps,
  FlexboxProps,
  BorderProps,
  PositionProps,
} from "styled-system";

export type BoxProps = SpaceProps &
  ColorProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  PositionProps & {
    gap?: string;
    color?: string;
    transform?: string
  };

export const Box = styled.div<BoxProps>`
  ${({ gap }) => (gap ? `gap: ${gap}px;` : "")}

  ${space}
  ${color}
    ${space}
    ${typography}
    ${layout}
    ${flexbox}
    ${background}
    ${border}
    ${position}
`;
