import { H2 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import download from "../../assets/2FA/download-arrow.svg";
import otp from "../../assets/2FA/otp.svg";
import password from "../../assets/2FA/password.svg";
import { Image } from "../../components/Image";
import { Flex } from "../../components/Flex";
import { Button } from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../routes";
import { Enable2FAModal } from "../../components/Modals/2FA/Enable2FAModal";
import { useState } from "react";

export const Setup2FA = () => {
  const navigate = useNavigate();
  const [isEnable2FAModalOpen, setIsEnable2FAModalOpen] = useState(false);

  const handleClose = () => {
    setIsEnable2FAModalOpen(false);
  };

  const handleSuccessClose = () => {
    setIsEnable2FAModalOpen(false);
    navigate(ROUTE.ADD_COMPANY);
  };

  const handleSkip = () => {
    navigate(ROUTE.ADD_COMPANY);
  };

  const handleSetup = () => {
    setIsEnable2FAModalOpen(true);
  };
  return (
    <>
      <H2>Set Up 2 Factor Authentication</H2>
      <Paragraph>
        Two-Factor Authentication (2FA) is an added layer of security for your
        account.
      </Paragraph>

      <Paragraph fontWeight={600}>How it Works</Paragraph>

      <Flex
        justifyContent={"center"}
        backgroundColor={"gray.100"}
        borderRadius={"50%"}
        height={60}
        width={60}
      >
        <Image src={download} alt="Download Icon" height="auto" width="22px" />
      </Flex>
      <Paragraph>
        First, you'll need to download an authenticator app on your mobile
        device such as Microsoft or Google Authenticator.
      </Paragraph>

      <Flex
        justifyContent={"center"}
        backgroundColor={"gray.100"}
        borderRadius={"50%"}
        height={60}
        width={60}
      >
        <Image src={otp} alt="Download Icon" height="auto" width="22px" />
      </Flex>
      <Paragraph>
        After installation, the app will generate a temporary OTP every 30
        seconds.
      </Paragraph>
      <Flex
        justifyContent={"center"}
        backgroundColor={"gray.100"}
        borderRadius={"50%"}
        height={60}
        width={60}
      >
        <Image src={password} alt="Download Icon" height="auto" width="22px" />
      </Flex>
      <Paragraph>
        During login, input your password, followed by the current OTP from the
        app.
      </Paragraph>

      <Button my={3} variant="secondary" onClick={handleSkip}>
        Skip for now
      </Button>
      <Button variant="primary" onClick={handleSetup}>
        Set up
      </Button>

      <Enable2FAModal
        isOpen={isEnable2FAModalOpen}
        onRequestClose={handleClose}
        initialNextClicked={true}
        onSuccessClose={handleSuccessClose}
      />
    </>
  );
};
