import { Box } from "../../components/Box";
import { Flex } from "../../components/Flex";
import { Paragraph } from "../../components/Paragraph";
import { IndividualDetails } from "../../types/Individual";
import { CheckHeading, CheckParagraph } from "./CheckStyledComponents";
import { H3 } from "../../components/Heading";
import styled from "styled-components";
import { Card } from "../../components/Card";
import { capitalise } from "../../utils/string";

type Props = {
  data: IndividualDetails;
};
const Heading = styled(H3)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[2]};
    word-break: break-all;
  `}
`;
const Para = styled(Paragraph)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[2]};
    word-break: break-all;
    margin: 0px;
  `}
`;
const CardLine = ({ children, ...styleProps }: any) => (
  <Paragraph fontSize={1} color="gray.60" my={0} {...styleProps}>
    {children}
  </Paragraph>
);

export const AddressIndividualInformation = (props: Props) => {
  const { data } = props;
  
  const abc = (
    <>
      <CardLine>
        {data?.address
          ?.flat_appartment_subbuilding && (
            <span>
              {
                data?.address
                  ?.flat_appartment_subbuilding
              }
              &nbsp;
            </span>
          )}
        {data?.address?.building_number && (
          <span>
            {data?.address?.building_number}
            &nbsp;
          </span>
        )}
        {data?.address?.building_name && (
          <span>
            {data?.address?.building_name}
            &nbsp;
          </span>
        )}
        {data?.address?.road_street}
      </CardLine>
      <CardLine>
        {data?.address?.town_city && (
          <span>
            {data?.address?.town_city}
            &nbsp;
          </span>
        )}
        {data?.address?.district}
      </CardLine>
      <CardLine>
        {data?.address?.post_zip_code}
      </CardLine>
      <CardLine>
        {data?.address?.country_name}
      </CardLine>
    </>
  )
  return (
    <Card mb={2}>
      <Flex gap="24">
        <Box flex={2}>
          <Heading mb={2}>Individual Information</Heading>
          <Paragraph>Individual details used for address verification</Paragraph>
        </Box>
        
        <Box flex={1} mr={5}>
          <Flex alignItems="center">
            <CheckHeading minWidth={200}>First Name:</CheckHeading>
            <CheckParagraph>
              {data?.personal_detail?.first_name
                ? capitalise(data?.personal_detail?.first_name)
                : "-"}
            </CheckParagraph>
          </Flex>
          <Flex alignItems="center">
            <Heading minWidth={200}>Middle Name(s):</Heading>
            <div>
            <CardLine>
              {data?.personal_detail?.middle_name
                ? capitalise(data?.personal_detail?.middle_name)
                : "-"}
            </CardLine>
            </div>
          </Flex>
          <Flex alignItems="center">
            <Heading minWidth={200}>Surname:</Heading>
            <CardLine>
              {data?.personal_detail?.last_name
                ? capitalise( data?.personal_detail?.last_name)
                : "-"}
            </CardLine>
          </Flex>
          <Flex alignItems="center">
            <Heading minWidth={200}>Date Of Birth:</Heading>
            <CardLine>
              {data?.personal_detail?.date_of_birth
                ? data?.personal_detail?.date_of_birth
                : "-"}
            </CardLine>
          </Flex>
          <Flex alignItems="center">
            <Heading  minWidth={200}>Sex:</Heading>
            <CardLine>
              {data?.personal_detail?.gender
                ?  capitalise(
                  data?.personal_detail?.gender
                )
                : "-"}
            </CardLine>
          </Flex>
          <Flex alignItems="center">
            <Heading  minWidth={200}>Address:</Heading>
            <div>
              {data?.address
                ? abc
                : "-"}
                </div>
          </Flex>
        </Box>

      </Flex>
    </Card>
  );
};
