import styled from "styled-components";
import { fadeIn } from "../../components/animation";
import Topbar from "../../components/Topbar";
import { ProfileUserDetails } from "./ProfileUserDetails";
import { ProfilePasswordReset } from "./ProfilePasswordReset";
import { Box } from "../../components/Box";
import { Settings2FactorEnableDisable } from "../2FactorAuthentication/Settings2FactorEnableDisable";

const Wrapper = styled.div`
  animation: fadein 0.5s;
  ${fadeIn}
  ${({ theme: { space } }) => `
    margin: ${space[3]};
  `}
`;

export const Profile = () => {
  return (
    <>
      <Topbar title="My Profile" />
      <Box height="calc(100vh - 83px)" overflowY="auto">
        <Wrapper>
          <ProfileUserDetails />
        </Wrapper>
        <Wrapper>
          <ProfilePasswordReset />
        </Wrapper>
        <Wrapper>
          <Settings2FactorEnableDisable />
        </Wrapper>
      </Box>
    </>
  );
};
