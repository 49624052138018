import { useEffect, useState } from "react";
import { Box } from "../../components/Box";
import { H2, H3 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import SplashScreen from "../../components/SplashScreen";
import { SketchPicker } from "react-color";
import { Input } from "../../components/Input";
import { Flex } from "../../components/Flex";
import { Toggle } from "../../components/Toggle";
import { Form, Formik } from "formik";
import styled from "styled-components";
import { ImageUpload } from "../../components/ImageUpload";
import { useSplashScreen } from "../../data/useSplashScreen";
import { Loader } from "../../components/Loader";
import { Button } from "../../components/Button";
import { useUpdateSplashScreen } from "../../data/useUpdateSplashScreen";
import { queryClient } from "../../queryClient";
import { showError } from "../../utils/error-handling";
import { usePostSplashScreen } from "../../data/usePostSplashScreen";
import { notify } from "../../utils/notify";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { BrandingValidation } from "../../utils/validationSchema";
import { useDeleteBrandingLogo } from "../../data/useDeleteBrandingLogo";
import { Modal } from "../../components/Modal";
import { Link } from "../../components/Link";
import { env } from "../../utils/env";
import { RiDeleteBin6Line } from "react-icons/ri";
import theme from "../../theme";
import { BiPlus } from "react-icons/bi";

// Styled component for the color box
const ColorBox = styled.div<{ buttonColor: string }>`
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background: ${(props) => props.buttonColor};
  border: 1px solid ${({ theme }) => theme.colors.gray[60]};
`;

const SplashSection = styled.div`
  ${({ theme: { space } }) => `
  margin: ${space[3]} 0;
  `}
`;

// Styled component for the color button
const ColorButton = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
`;

// Styled component for the color picker overlay
const ColorPickerOverlay = styled.div`
  position: absolute;
  z-index: 2;
  // max-height: 300px; /* Adjust as needed */
  // overflow-y: auto;
`;

// Styled component for the cover overlay
const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  // height: max-content;
`;

const Heading = styled(H3)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[2]};
    font-weight: 600;
  `}
`;

const SubHeading = styled(Paragraph)`
  ${({ theme: { fontSizes, colors } }) => `
    font-size: ${fontSizes[1]};
    font-weight: 600;
    color: ${colors.gray[60]};
  `}
`;

const SettingsBranding = () => {
  const { authHeader } = useAuth();
  const { data, isLoading } = useSplashScreen();
  const {
    mutate: updateSplashScreenData,
    isLoading: isUpdatingSplashScreenData,
  } = useUpdateSplashScreen();

  const { mutate: AddSplashScreenData, isLoading: isAddingSplashScreenData } =
    usePostSplashScreen();

  const { mutate: deleteBrandingLogo } = useDeleteBrandingLogo();

  const [isRequestingImage, setIsRequestingImage] = useState(false);

  const [img, setImg] = useState("");

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [disableToggle, setDisableToggle] = useState(false);

  useEffect(() => {
    if (!isLoading && data?.logo) {
      fetchImage();
    }
  }, [data?.logo]);

  const fetchImage = () => {
    if (isRequestingImage) {
      return;
    }

    setIsRequestingImage(true);
    axios
      .get(data?.logo, {
        headers: {
          Authorization: authHeader,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data]);
        const imageObjectURL = window.URL.createObjectURL(blob);
        setImg(imageObjectURL);
      })
      .finally(() => {
        setIsRequestingImage(false);
      });
  };

  const [displayButtonColorPicker, setDisplayButtonColorPicker] =
    useState(false);
  const [displayBackgroundColorPicker, setDisplayBackgroundColorPicker] =
    useState(false);
  const [displayFontColorPicker, setDisplayFontColorPicker] = useState(false);
  const [displayButtonFontColorPicker, setDisplayButtonFontColorPicker] =
    useState(false);
  const [displayLinkFontColorPicker, setDisplayLinkFontColorPicker] =
    useState(false);

  const handleButtonColorClick = () =>
    setDisplayButtonColorPicker(!displayButtonColorPicker);
  const handleBackgroundColorClick = () =>
    setDisplayBackgroundColorPicker(!displayBackgroundColorPicker);
  const handleFontColorClick = () =>
    setDisplayFontColorPicker(!displayFontColorPicker);
  const handleButtonFontColorClick = () =>
    setDisplayButtonFontColorPicker(!displayButtonFontColorPicker);
  const handleLinkFontColorClick = () =>
    setDisplayLinkFontColorPicker(!displayLinkFontColorPicker);

  const handleClose = () => {
    setDisplayButtonColorPicker(false);
    setDisplayBackgroundColorPicker(false);
    setDisplayFontColorPicker(false);
    setDisplayButtonFontColorPicker(false);
    setDisplayLinkFontColorPicker(false);
  };

  const handleSubmit = (formData) => {
    const obj = {
      display_splash_screen: formData.displaySplashScreen,
      use_display_name: formData.useDisplayname,
      description: formData.text || "",
      tandc_link: formData.termsLink,
      privacy_policy_link: formData.privacyLink,
      button_card_display: formData.buttonColor,
      background: formData.backgroundColor,
      font: formData.fontColor,
      button_font_color: formData.buttonFontColor,
      link_font_color: formData.linkFontColor,
      ...(formData?.logo && typeof formData?.logo !== "string"
        ? { logo: formData.logo?.get("logo") }
        : null),
      additional_information: formData.additional_information,
      display_terms: formData.display_terms,
      additional_text: formData.additional_text,
      additional_links: JSON.stringify(formData.additional_links),
    };

    const submitData = new FormData();
    Object.keys(obj).forEach((key) => {
      submitData.append(key, obj[key]);
    });

    if (data && data.id) {
      updateSplashScreenData(
        { id: data.id, params: submitData },
        {
          onSuccess: (res) => {
            if (res?.status?.toString().startsWith("2")) {
              notify("Splash screen data updated successfully!", {
                position: "bottom-left",
                type: "success",
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                autoClose: 25,
              });
              queryClient.invalidateQueries(["splashScreenList"]);
            }
          },
          onError: (err: any) => {
            showError(err, "A problem occurred while updating.");
          },
        }
      );
    } else {
      AddSplashScreenData(
        { params: submitData },
        {
          onSuccess: (res) => {
            if (res?.status?.toString().startsWith("2")) {
              notify("Splash screen data added successfully!", {
                position: "bottom-left",
                type: "success",
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                autoClose: 25,
              });
              queryClient.invalidateQueries(["splashScreenList"]);
            }
          },
          onError: (err: any) => {
            showError(err, "A problem occurred while updating.");
          },
        }
      );
    }
  };

  const handleLogoChange = (data) => {
    const blob = new Blob([data]);
    const imageObjectURL = window.URL.createObjectURL(blob);
    setImg(imageObjectURL);
    // setImg(data); // Update the img state with the new logo URL
  };

  const handleDelete = (props) => {
    setImg(null);
    props.setFieldValue("logo", null);

    deleteBrandingLogo(
      { id: data.id },
      {
        onSuccess: () => {
          notify("Logo has been deleted", {
            position: "bottom-left",
            type: "success",
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            autoClose: 25,
          });
          // queryClient.invalidateQueries(["splashScreenList"]);
        },
        onError: (err: any) => {
          showError(
            err,
            "A problem occurred while updating the Applicaiton Type."
          );
        },
      }
    );
  };

  return (
    <>
      <Box bg="white" borderRadius={0} p={3} mb={3}>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <H2>Mobile app splash screen</H2>
        </Flex>

        <Paragraph color={"gray.60"} fontSize={1} mt={"0"} mb={3}>
          Add your branding, terms and company information to the Verify mobile
          app.
          <Link
            fontSize="1"
            href={env.verifyApplicationSplashScreenLearnMoreUrl}
            target="_blank"
            ml={2}
          >
            Learn more
          </Link>
        </Paragraph>

        {isLoading && (
          <Flex justifyContent="center" alignItems="center" minHeight="35vh">
            <Loader />
          </Flex>
        )}

        {!isLoading && (
          <>
            <Formik
              initialValues={{
                displaySplashScreen:
                  data?.display_splash_screen !== undefined
                    ? data?.display_splash_screen
                    : false,
                useDisplayname:
                  data?.use_display_name !== undefined
                    ? data?.use_display_name
                    : false,
                text: data?.description || "",
                additional_information: data?.additional_information || false,
                additional_text: data?.additional_text || "",
                additional_links: data?.additional_links || [
                  { link_url: "", link_text: "" },
                ],
                display_terms: data?.display_terms || false,
                termsLink: data?.tandc_link || "",
                privacyLink: data?.privacy_policy_link || "",
                buttonColor: data?.button_card_display || "#3158F2",
                backgroundColor: data?.background || "#FFFFFF",
                fontColor: data?.font || "#09093E",
                buttonFontColor: data?.button_font_color || "#000000",
                linkFontColor: data?.link_font_color || "#000000",
                logo: data?.logo || null,
              }}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
              enableReinitialize
              validationSchema={BrandingValidation}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <Flex gap="50" position={"relative"}>
                    <Box flex={1}>
                      <Flex
                        my={3}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Heading>Display splash screen</Heading>
                        <Toggle
                          mt={1}
                          isChecked={props.values.displaySplashScreen}
                          onChange={(e) => {
                            props.setFieldValue(
                              "displaySplashScreen",
                              e.target.checked
                            );
                          }}
                        />
                      </Flex>

                      <hr color={theme.colors.gray[5]} />

                      <Box mt={2} mb={4}>
                        <Heading>Logo (optional)</Heading>
                        <Paragraph color={"gray.60"} fontSize={1}>
                          Add your company logo
                        </Paragraph>
                        <ImageUpload
                          logoUrl={img}
                          onChange={(file) => {
                            const formData = new FormData();
                            formData.append("logo", file);

                            props.setFieldValue("logo", formData);
                            handleLogoChange(file);
                          }}
                          label="Upload a PNG or JPEG file"
                          text="Use an image with maximum dimensions of 1024px x 1024px"
                          buttonText="Upload Logo"
                          hasDeleteButton={data?.logo ? true : false}
                          onDelete={() => handleDelete(props)}
                        />
                      </Box>

                      <hr color={theme.colors.gray[5]} />

                      <SplashSection>
                        <Flex
                          my={3}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Paragraph m={"0px"} fontWeight={600}>
                            Use company display name
                          </Paragraph>

                          <Toggle
                            mt={1}
                            isDisabled={disableToggle}
                            isChecked={props.values.useDisplayname}
                            onChange={(e) => {
                              if (
                                e.target.checked === true &&
                                !data?.company_display_name
                              ) {
                                setShowErrorModal(true);
                              } else {
                                props.setFieldValue(
                                  "useDisplayname",
                                  e.target.checked
                                );
                              }
                            }}
                          />
                        </Flex>

                        <Paragraph my={3} fontSize={1} color={"gray.60"}>
                          Your company display name is{" "}
                          <strong>
                            {data?.company_display_name
                              ? data?.company_display_name
                              : "-"}
                          </strong>
                        </Paragraph>
                      </SplashSection>

                      <hr color={theme.colors.gray[5]} />

                      <SplashSection>
                        <Box mt={2} mb={3}>
                          <Heading>Welcome message</Heading>
                          <Input
                            onChange={(e) =>
                              props.setFieldValue("text", e.target.value)
                            }
                            value={props.values.text}
                            type="text"
                            placeholder="Add text"
                            name="message"
                            subLabel="Write a welcome message to your customers"
                            isRequired
                            hasError={Boolean(props.errors.text)}
                            errorMessage={props.errors.text}
                          />
                        </Box>
                      </SplashSection>

                      <hr color={theme.colors.gray[5]} />

                      <SplashSection>
                        <Flex
                          my={3}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Paragraph m={"0px"} fontWeight={600}>
                            Additional information / links
                          </Paragraph>

                          <Toggle
                            mt={1}
                            isChecked={props.values.additional_information}
                            onChange={(e) => {
                              props.setFieldValue(
                                "additional_information",
                                e.target.checked
                              );
                            }}
                          />
                        </Flex>

                        {props.values.additional_information && (
                          <Box>
                            <Paragraph color={"gray.60"} fontSize={1} my={2}>
                              Provide additional information, links and
                              documents.
                            </Paragraph>

                            <Input
                              mb={3}
                              onChange={(e) =>
                                props.setFieldValue(
                                  "additional_text",
                                  e.target.value
                                )
                              }
                              value={props.values.additional_text}
                              type="text"
                              placeholder="Add text"
                              label="Information text"
                              name="additional_text"
                              isRequired
                              hasError={Boolean(props.errors.additional_text)}
                              errorMessage={props.errors.additional_text}
                            />

                            {props.values.additional_links.map(
                              (link, index) => (
                                <Box key={index} mb={3} alignItems="center">
                                  <Flex
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                  >
                                    <Paragraph>Link {index + 1}</Paragraph>

                                    <Button
                                      type="button"
                                      variant="danger-text"
                                      iconColor="red.primary"
                                      Icon={RiDeleteBin6Line}
                                      onClick={() => {
                                        const updatedLinks =
                                          props.values.additional_links.filter(
                                            (_, idx) => idx !== index
                                          );
                                        props.setFieldValue(
                                          "additional_links",
                                          updatedLinks
                                        );
                                      }}
                                    >
                                      {""}
                                    </Button>
                                  </Flex>

                                  <Input
                                    my={2}
                                    onChange={(e) => {
                                      const updatedLinks =
                                        props.values.additional_links.map(
                                          (link, idx) =>
                                            idx === index
                                              ? {
                                                  ...link,
                                                  link_text: e.target.value,
                                                }
                                              : link
                                        );
                                      props.setFieldValue(
                                        "additional_links",
                                        updatedLinks
                                      );
                                    }}
                                    value={link.link_text}
                                    type="text"
                                    placeholder="Add link text"
                                    hasError={
                                      Array.isArray(
                                        props.errors.additional_links
                                      ) &&
                                      typeof props.errors.additional_links[
                                        index
                                      ] === "object" &&
                                      props.errors.additional_links[index] !==
                                        null &&
                                      Boolean(
                                        (
                                          props.errors.additional_links[
                                            index
                                          ] as any
                                        )?.link_text
                                      )
                                    }
                                    errorMessage={
                                      Array.isArray(
                                        props.errors.additional_links
                                      ) &&
                                      typeof props.errors.additional_links[
                                        index
                                      ] === "object" &&
                                      props.errors.additional_links[index] !==
                                        null &&
                                      (
                                        props.errors.additional_links[
                                          index
                                        ] as any
                                      )?.link_text
                                    }
                                  />

                                  <Input
                                    my={2}
                                    onChange={(e) => {
                                      const updatedLinks =
                                        props.values.additional_links.map(
                                          (link, idx) =>
                                            idx === index
                                              ? {
                                                  ...link,
                                                  link_url: e.target.value,
                                                }
                                              : link
                                        );

                                      props.setFieldValue(
                                        "additional_links",
                                        updatedLinks
                                      );
                                    }}
                                    value={link.link_url}
                                    type="text"
                                    placeholder="Add link URL"
                                    hasError={
                                      Array.isArray(
                                        props.errors.additional_links
                                      ) &&
                                      typeof props.errors.additional_links[
                                        index
                                      ] === "object" &&
                                      props.errors.additional_links[index] !==
                                        null &&
                                      Boolean(
                                        (
                                          props.errors.additional_links[
                                            index
                                          ] as any
                                        )?.link_url
                                      )
                                    }
                                    errorMessage={
                                      Array.isArray(
                                        props.errors.additional_links
                                      ) &&
                                      typeof props.errors.additional_links[
                                        index
                                      ] === "object" &&
                                      props.errors.additional_links[index] !==
                                        null &&
                                      (
                                        props.errors.additional_links[
                                          index
                                        ] as any
                                      )?.link_url
                                    }
                                  />
                                </Box>
                              )
                            )}
                            <Button
                              type="button"
                              p={0}
                              Icon={BiPlus}
                              // iconSize="16px"
                              variant="grayText"
                              onClick={() =>
                                props.setFieldValue("additional_links", [
                                  ...props.values.additional_links,
                                  { link_text: "", link_url: "" },
                                ])
                              }
                            >
                              Add link
                            </Button>
                          </Box>
                        )}
                      </SplashSection>

                      <hr color={theme.colors.gray[5]} />
                      <SplashSection>
                        <Flex
                          my={3}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Paragraph m={"0px"} fontWeight={600}>
                            Terms and conditions
                          </Paragraph>

                          <Toggle
                            mt={1}
                            isChecked={props.values.display_terms}
                            onChange={(e) => {
                              props.setFieldValue(
                                "display_terms",
                                e.target.checked
                              );
                            }}
                          />
                        </Flex>
                        {props.values.display_terms && (
                          <Box>
                            <Paragraph color={"gray.60"} fontSize={1}>
                              Provide a link to your Terms and Conditions and
                              Privacy Policy for acceptance
                            </Paragraph>

                            <Input
                              mb={3}
                              onChange={(e) =>
                                props.setFieldValue("termsLink", e.target.value)
                              }
                              value={props.values.termsLink}
                              type="text"
                              placeholder="Add link"
                              label="Terms and conditions link"
                              name="termsLink"
                              isRequired
                              hasError={Boolean(props.errors.termsLink)}
                              errorMessage={props.errors.termsLink}
                            />

                            <Input
                              mb={3}
                              onChange={(e) =>
                                props.setFieldValue(
                                  "privacyLink",
                                  e.target.value
                                )
                              }
                              value={props.values.privacyLink}
                              type="text"
                              placeholder="Add link"
                              label="Privacy policy link"
                              name="privacyLink"
                              isRequired
                              hasError={Boolean(props.errors.privacyLink)}
                              errorMessage={props.errors.privacyLink}
                            />
                          </Box>
                        )}
                      </SplashSection>

                      <hr color={theme.colors.gray[5]} />
                      <SplashSection>
                        <Box>
                          <Heading mb={3}>Colours</Heading>
                        </Box>

                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          mb={1}
                        >
                          <SubHeading>Button/card display</SubHeading>
                          <Flex alignItems={"center"} gap="18">
                            <ColorButton onClick={handleButtonColorClick}>
                              <ColorBox
                                buttonColor={props.values.buttonColor}
                              ></ColorBox>
                            </ColorButton>
                            {displayButtonColorPicker ? (
                              <ColorPickerOverlay>
                                <Cover onClick={handleClose} />
                                <SketchPicker
                                  color={props.values.buttonColor}
                                  onChange={(color) => {
                                    props.setFieldValue(
                                      "buttonColor",
                                      color.hex
                                    );
                                  }}
                                />
                              </ColorPickerOverlay>
                            ) : null}
                            <Input
                              onChange={(e) =>
                                props.setFieldValue(
                                  "buttonColor",
                                  e.target.value
                                )
                              }
                              value={props.values.buttonColor}
                              type="text"
                              placeholder="Add color"
                              name="buttonColor"
                              isDisabled={true}
                            />
                          </Flex>
                        </Flex>

                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          mb={1}
                        >
                          <SubHeading>Background</SubHeading>
                          <Flex alignItems={"center"} gap="18">
                            <ColorButton onClick={handleBackgroundColorClick}>
                              <ColorBox
                                buttonColor={props.values.backgroundColor}
                              ></ColorBox>
                            </ColorButton>

                            {displayBackgroundColorPicker ? (
                              <ColorPickerOverlay>
                                <Cover onClick={handleClose} />
                                <SketchPicker
                                  color={props.values.backgroundColor}
                                  onChange={(color) => {
                                    props.setFieldValue(
                                      "backgroundColor",
                                      color.hex
                                    );
                                  }}
                                />
                              </ColorPickerOverlay>
                            ) : null}

                            <Input
                              onChange={(e) =>
                                props.setFieldValue(
                                  "backgroundColor",
                                  e.target.value
                                )
                              }
                              value={props.values.backgroundColor}
                              type="text"
                              placeholder="Add color"
                              name="backgroundColor"
                              isDisabled={true}
                            />
                          </Flex>
                        </Flex>

                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          mb={1}
                        >
                          <SubHeading>Font</SubHeading>
                          <Flex alignItems={"center"} gap="18">
                            <ColorButton onClick={handleFontColorClick}>
                              <ColorBox
                                buttonColor={props.values.fontColor}
                              ></ColorBox>
                            </ColorButton>

                            {displayFontColorPicker ? (
                              <ColorPickerOverlay>
                                <Cover onClick={handleClose} />
                                <SketchPicker
                                  color={props.values.fontColor}
                                  onChange={(color) => {
                                    props.setFieldValue("fontColor", color.hex);
                                  }}
                                />
                              </ColorPickerOverlay>
                            ) : null}

                            <Input
                              onChange={(e) =>
                                props.setFieldValue("fontColor", e.target.value)
                              }
                              value={props.values.fontColor}
                              type="text"
                              placeholder="Add color"
                              name="fontColor"
                              isDisabled={true}
                            />
                          </Flex>
                        </Flex>

                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          mb={1}
                        >
                          <Box>
                            <SubHeading>Button font Colour</SubHeading>
                          </Box>
                          <Flex alignItems={"center"} gap="18">
                            <ColorButton onClick={handleButtonFontColorClick}>
                              <ColorBox
                                buttonColor={props.values.buttonFontColor}
                              ></ColorBox>
                            </ColorButton>

                            {displayButtonFontColorPicker ? (
                              <ColorPickerOverlay>
                                <Cover onClick={handleClose} />
                                <SketchPicker
                                  color={props.values.buttonFontColor}
                                  onChange={(color) => {
                                    props.setFieldValue(
                                      "buttonFontColor",
                                      color.hex
                                    );
                                  }}
                                />
                              </ColorPickerOverlay>
                            ) : null}

                            <Input
                              onChange={(e) =>
                                props.setFieldValue(
                                  "buttonFontColor",
                                  e.target.value
                                )
                              }
                              value={props.values.buttonFontColor}
                              type="text"
                              placeholder="Add color"
                              name="buttonFontColor"
                              isDisabled={true}
                            />
                          </Flex>
                        </Flex>

                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          mb={1}
                        >
                          <SubHeading>Link font Colour</SubHeading>
                          <Flex alignItems={"center"} gap="18">
                            <ColorButton onClick={handleLinkFontColorClick}>
                              <ColorBox
                                buttonColor={props.values.linkFontColor}
                              ></ColorBox>
                            </ColorButton>

                            {displayLinkFontColorPicker ? (
                              <ColorPickerOverlay>
                                <Cover onClick={handleClose} />
                                <SketchPicker
                                  color={props.values.linkFontColor}
                                  onChange={(color) => {
                                    props.setFieldValue(
                                      "linkFontColor",
                                      color.hex
                                    );
                                  }}
                                />
                              </ColorPickerOverlay>
                            ) : null}

                            <Input
                              onChange={(e) =>
                                props.setFieldValue(
                                  "linkFontColor",
                                  e.target.value
                                )
                              }
                              value={props.values.linkFontColor}
                              type="text"
                              placeholder="Add color"
                              name="linkFontColor"
                              isDisabled={true}
                            />
                          </Flex>
                        </Flex>
                      </SplashSection>
                      <Button
                        type="submit"
                        my={2}
                        isDisabled={
                          !props.dirty ||
                          !props.isValid ||
                          isUpdatingSplashScreenData ||
                          isAddingSplashScreenData
                        }
                      >
                        Save
                      </Button>
                    </Box>
                    <Box flex={1}>
                      <SplashScreen
                        companyName={
                          props.values.useDisplayname
                            ? data?.company_display_name
                            : data?.company_name
                        }
                        message={props.values.text}
                        displayTerms={props.values.display_terms}
                        additionalInformation={
                          props.values.additional_information
                        }
                        additionalText={props.values.additional_text}
                        additionalLinks={props.values.additional_links}
                        buttonColor={props.values.buttonColor}
                        termsLink={props.values.termsLink}
                        privacyLink={props.values.privacyLink}
                        backgroundColor={props.values.backgroundColor}
                        fontColor={props.values.fontColor}
                        buttonFontColor={props.values.buttonFontColor}
                        linkFontColor={props.values.linkFontColor}
                        logo={img}
                      />
                    </Box>
                  </Flex>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Box>

      <Modal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        width="300px"
        // title={}
        content={
          "Please provide the display name in the Company Details general setting area, if you want this name to display on the Mobile APP splash screen."
        }
        primaryButtonText="Ok"
        primaryButtonVariant="primary"
        onModalConfirmClick={() => {
          setDisableToggle(true);
          setShowErrorModal(false);
        }}
      />
    </>
  );
};

export default SettingsBranding;
