import axios from "axios";
import { env } from "../utils/env";
import { useAuth } from "../context/AuthContext";
import { useQuery } from "@tanstack/react-query";

const fetch = (authHeader: string) => () =>
  axios.get(`${env.verifyServiceUrl}user/two_factor/generate_qr_code`, {
    headers: {
      Authorization: authHeader,
    },
    responseType: "arraybuffer",
  });

export const useGenerateQRCode = () => {
  const { authHeader } = useAuth();
  return useQuery(["generateQRCode"], fetch(authHeader), {
    retry: false,
  });
};
