import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

export const useDisable2FA = () => {
  const { authHeader } = useAuth();

  return useMutation(({ skip = false }: { skip?: boolean }) =>
    axios.post(
      `${env.verifyServiceUrl}user/two_factor/disable/`,
      { skip },
      {
        headers: {
          Authorization: authHeader,
        },
      }
    )
  );
};
