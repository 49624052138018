import { useNavigate, useParams } from "react-router-dom";
import { Box } from "../../components/Box";
import { Flex } from "../../components/Flex";
import { H2, H3 } from "../../components/Heading";
import { Loader } from "../../components/Loader";
import { useMandateTypeAdditionalDoc } from "../../data/useMandateTypeAdditionalDoc";
import { ApplicationTypeNewFrame } from "./ApplicationTypeNewFrame";
import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useDirtyForm } from "./DirtyFormContext";
import { Toggle } from "../../components/Toggle";
import { queryClient } from "../../queryClient";
import { showError } from "../../utils/error-handling";
import { useUpdateMandateType } from "../../data/useUpdateMandateType";
import { notify } from "../../utils/notify";
import { Paragraph } from "../../components/Paragraph";
import { Button } from "../../components/Button";
import { FaPlus } from "react-icons/fa";
import { AddEditAdditionalDocument } from "../../components/Modals/AddEditAdditionalDocument";
import { ROUTE } from "../../routes";
import { useMandateType } from "../../data/useMandateType";
import { useChecks } from "../../data/useChecks";

const SettingsApplicationTypeNewDocuments = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { setFormDirty, formRef } = useDirtyForm();
  const [selectedMandateTypeId, setSelectedMandateTypeId] = useState<
    number | null
  >(null);

  const { data, isLoading, error } = useMandateTypeAdditionalDoc(
    selectedMandateTypeId
  );

  const { mutate: updateMandateType } = useUpdateMandateType();

  const { data: mandateTypeQuery } = useMandateType(selectedMandateTypeId);

  const { data: checkListQuery } = useChecks();
  const checkList = checkListQuery?.data;

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  useEffect(() => {
    if (params.id) {
      const id = parseInt(params.id);
      setSelectedMandateTypeId(id);
    }
  }, [params.id]);

  // Check if data is available before using it
  const addedDocumentIds = data
    ? data.filter((doc) => doc.is_added === true).map((doc) => doc.id)
    : [];

  const getInitialValues = () => {
    if (!data) return {};

    const values = formRef.current?.values || {};
    const activeDocumentIds = Object.keys(values)
      .filter((key) => key.startsWith("active_") && values[key])
      .map((key) => parseInt(key.replace("active_", "")));

    const initialValues = {};
    data.forEach((item) => {
      initialValues[`active_${item.id}`] =
        activeDocumentIds.includes(item.id) || item.is_added || false;
    });

    return initialValues;
  };

  const handleActiveChange = (id: number, is_added: boolean) => {
    let additionalDocumentArray: number[] = [...addedDocumentIds];

    if (is_added) {
      if (!additionalDocumentArray.includes(id)) {
        additionalDocumentArray.push(id);
      }
    } else {
      additionalDocumentArray = additionalDocumentArray.filter(
        (docId) => docId !== id
      );
    }
  };

  const addAdditionalDoc = () => {
    setIsEditModalOpen(true);
    setSelectedDocumentId(null);
  };

  const handleClose = () => {
    setIsEditModalOpen(false);
  };

  const handleDocumentSuccess = (id: number) => {
    let additionalDocumentArray: number[] = [...addedDocumentIds];
    additionalDocumentArray.push(id);
    updateMandateType(
      {
        params: {
          id: selectedMandateTypeId,
          additional_document: additionalDocumentArray,
        },
        id: selectedMandateTypeId,
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries(["mandateTypeAdditionalDoc"]);
        },
        onError: (err) => {
          showError(err, "Error occured while updating");
        },
      }
    );
  };

  const handleSubmit = (values) => {
    const additionalDocumentArray = Object.keys(values)
      .filter((key) => key.startsWith("active_") && values[key]) // Keep only active documents
      .map((key) => parseInt(key.replace("active_", ""))); // Extract the document IDs

    if (additionalDocumentArray.length > 0) {
      // Get the existing checkpoint IDs and push additional doc check id into the array
      const currentCheckpoints =
        mandateTypeQuery?.data?.checkpoint?.map((cp) => cp.id) || [];

      const additionalDocumentCheckId = checkList?.find(
        (check) => check.name === "additional_document_check"
      ).id;

      const newCheckpointArray = [
        ...currentCheckpoints,
        additionalDocumentCheckId,
      ];

      const obj = {
        id: selectedMandateTypeId,
        is_disabled: false,
        checkpoint: newCheckpointArray,
        additional_document: additionalDocumentArray,
      };

      updateMandateType(
        { params: obj, id: selectedMandateTypeId },
        {
          onSuccess: (res) => {
            if (res?.status?.toString().startsWith("2")) {
              notify("Application Type updated successfully!", {
                type: "success",
              });
            }
            navigate(ROUTE.SETTINGS_APPLICATION_TYPES);
          },
          onError: (err) => {
            showError(err, "Error occurred while updating");
          },
        }
      );
    } else {
      const obj = {
        id: selectedMandateTypeId,
        is_disabled: false,
        additional_document: additionalDocumentArray,
      };

      updateMandateType(
        { params: obj, id: selectedMandateTypeId },
        {
          onSuccess: (res) => {
            if (res?.status?.toString().startsWith("2")) {
              notify("Application Type updated successfully!", {
                type: "success",
              });
            }
            navigate(ROUTE.SETTINGS_APPLICATION_TYPES);
          },
          onError: (err) => {
            showError(err, "Error occurred while updating");
          },
        }
      );
    }
  };

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" minHeight="25vh">
        <Loader />
      </Flex>
    );
  }

  if (error) {
    return <div>Error loading data.</div>;
  }

  return (
    <ApplicationTypeNewFrame step="documents">
      <Box pl={4} pr={2} pt={4} flex={4} minWidth={400}>
        <H2 fontSize="3">Create New Application Type</H2>
        <Box pt={2}>
          <Flex justifyContent="space-between" alignItems="center">
            <H2 fontSize={3} fontWeight={500}>
              Requests additional documents (optional)
            </H2>
            <Button
              Icon={FaPlus}
              iconSize="16px"
              onClick={addAdditionalDoc}
              variant="primary"
              type="button"
              fontWeight={"bold"}
            >
              Add additional document
            </Button>
          </Flex>
          {!data || data.length === 0 ? (
            <Flex justifyContent="center" alignItems="center" minHeight="25vh">
              <Loader />
            </Flex>
          ) : (
            <Formik
              initialValues={getInitialValues()}
              enableReinitialize
              validate={() => null}
              onSubmit={(values, actions) => {
                setFormDirty(false);
                actions.setSubmitting(false);
                handleSubmit(values);
              }}
              innerRef={formRef}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <Box>
                    {data?.map((item) => (
                      <Flex
                        key={item.id}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        borderBottom={"1px solid"}
                        borderBottomColor="gray.10"
                        py={2}
                      >
                        <Box>
                          <H3 fontSize={2} mb={"0px"}>
                            {item.name}
                          </H3>
                          <Paragraph my={0} color={"gray.60"}>
                            {item.description}
                          </Paragraph>
                        </Box>
                        <Box>
                          <Toggle
                            isChecked={
                              props.values[`active_${item.id}`] || false
                            }
                            onChange={(e) => {
                              props.setFieldValue(
                                `active_${item.id}`,
                                e.target.checked
                              );
                              handleActiveChange(item.id, e.target.checked);
                              setFormDirty(true);
                            }}
                            mt={2}
                          />
                        </Box>
                      </Flex>
                    ))}
                  </Box>

                  <Flex py={4} justifyContent="end">
                    <Button type="submit" size="regular">
                      Finish
                    </Button>
                  </Flex>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </Box>

      <AddEditAdditionalDocument
        isOpen={isEditModalOpen}
        onRequestClose={handleClose}
        documentId={selectedDocumentId}
        fromMandateType={true}
        onSuccess={handleDocumentSuccess} // Pass the success handler
      />
    </ApplicationTypeNewFrame>
  );
};

export default SettingsApplicationTypeNewDocuments;
