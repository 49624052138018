import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { Flex } from "../../components/Flex";
import { H1 } from "../../components/Heading";
import { Input } from "../../components/Input";
import { Paragraph } from "../../components/Paragraph";
import { ROUTE } from "../../routes";
import { Image } from "../../components/Image";
import tillerVerifySvg from "../../assets/verify-tiller.svg";
import { useState } from "react";
import loginSvg from "../../assets/login.svg";
import { showError } from "../../utils/error-handling";
import { end } from "@popperjs/core";
import { useRecoveryEmail } from "../../data/useRecoveryEmail";

const RecoveryEmail = () => {
  const [doubleClick, setDoubleClick] = useState(false);
  const navigate = useNavigate();
  const { mutate: recoveryEmail } = useRecoveryEmail();

  const handleSubmit = (email, setFieldError) => {
    setDoubleClick(true);
    recoveryEmail(email, {
      onSuccess: () => {
        setDoubleClick(false);
        navigate(ROUTE.EMAIL_OTP, { state: { email } });
      },
      onError: (err: any) => {
        setFieldError(
          "recoveryEmail",
          "The code entered is invalid or already used."
        );
        showError(err, "The code entered is invalid or already used.");
        setDoubleClick(false);
      },
    });
  };

  return (
    <Flex height="100vh">
      <Flex width="900px" flexDirection="column" p="60px" overflowY="auto">
        <Image
          src={tillerVerifySvg}
          alt="tiller verify logo"
          height="200px"
          width="160px"
        />

        <H1>Recover via Email</H1>
        <Paragraph my={4}>
          Enter your registered email address to receive a one-time password
          (OTP). You’ll use this code to verify your identity and access your
          account.
        </Paragraph>
        <Formik
          initialValues={{ recoveryEmail: "" }}
          onSubmit={(values, { setFieldError }) => {
            handleSubmit(values.recoveryEmail, setFieldError);
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Flex
                gap="6"
                alignItems={"center"}
                justifyContent={"space-around"}
              >
                <Input
                  width={"100%"}
                  onChange={props.handleChange}
                  value={props.values.recoveryEmail}
                  placeholder="Enter recovery email"
                  label="Confirm email address"
                  name="recoveryEmail"
                  isRequired
                  hasError={Boolean(props.errors.recoveryEmail)}
                  errorMessage={props.errors.recoveryEmail}
                />
              </Flex>
              <Flex my={2} justifyContent={"end"}>
                <Button
                  type="submit"
                  isDisabled={
                    !props.dirty ||
                    (props.dirty && !props.isValid) ||
                    doubleClick
                  }
                  mt="2"
                  size="large"
                  width="100%"
                >
                  Send One-Time Password (OTP)
                </Button>
              </Flex>

              <Paragraph my={4}>
                I would like to use recovery codes instead
              </Paragraph>

              <Flex justifyContent={end}>
                <Button
                  p={0}
                  variant="blueText"
                  onClick={() =>
                    navigate(ROUTE.USE_RECOVERY_CODE, { replace: true })
                  }
                >
                  Use Recovery Code
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
      <Flex
        bg={"background"}
        display={{ _: "none", md: "flex" }}
        height="100vh"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={loginSvg}
          alt="login image"
          display="block"
          width="55%"
          height="60%"
        />
      </Flex>
    </Flex>
  );
};

export default RecoveryEmail;
