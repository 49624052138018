import styled from "styled-components";
import { Box } from "../Box";
import { Button } from "../Button";
import { H2 } from "../Heading";
import { Link } from "../Link";
import { Image } from "../Image";
import { Paragraph } from "../Paragraph";
import { Flex } from "../Flex";

export const PreviewContainer = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.gray[10]};
  width: 100%;
  height: 70%;
  text-align: center;
  display: flex;
  align-items: start;
  justify-content: center;
  position: sticky;
  top: 0px;
`;

const StyledParagraph = styled(Paragraph)`
  ${({ theme: { fontSizes, colors, space }, color }) => `
    font-size: ${fontSizes[0]};
    word-break: break-all;
    color: ${color || colors.gray[60]}; 
  `}
`;

type SplashScreenProps = {
  companyName: string;
  message: string;
  displayTerms: boolean;
  additionalInformation: boolean;
  additionalText: string;
  additionalLinks: { link_url: string; link_text: string }[];
  buttonColor: string;
  backgroundColor: string;
  termsLink: string;
  privacyLink: string;
  fontColor: string;
  buttonFontColor: string;
  linkFontColor: string;
  logo: string;
};

const SplashScreen = ({
  companyName,
  message,
  displayTerms,
  additionalInformation,
  additionalText,
  additionalLinks,
  buttonColor,
  backgroundColor,
  termsLink,
  privacyLink,
  fontColor,
  buttonFontColor,
  linkFontColor,
  logo,
}: SplashScreenProps) => {
  return (
    <PreviewContainer>
      <Flex
        backgroundColor={backgroundColor}
        border={"2px solid"}
        borderColor={"blue.100"}
        width={200}
        borderRadius={16}
        flexDirection={"column"}
        alignItems={"center"}
        p={4}
        mt={"20%"}
      >
        {logo && (
          <Flex
            height={75}
            width={80}
            mb={2}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={logo} alt="logo" width="100%" height="100%" />
          </Flex>
        )}

        <H2 color={fontColor}>{companyName}</H2>

        <Box textAlign={"left"}>
          {message && (
            <StyledParagraph color={fontColor}>{message}</StyledParagraph>
          )}

          {additionalInformation && (
            <>
              {additionalText && (
                <StyledParagraph fontSize={0} color={fontColor}>
                  {additionalText}
                </StyledParagraph>
              )}

              {additionalLinks && additionalLinks.length > 0 && (
                <ul style={{ paddingLeft: 16 }}>
                  {additionalLinks.map((item, i) => (
                    <li style={{ marginBottom: 8 }} key={i}>
                      <Link
                        my={3}
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        href={item.link_url}
                        color={linkFontColor}
                        fontSize={0}
                      >
                        {item.link_text}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}

          <Box mt={"100px"}>
            {displayTerms && (
              <Paragraph fontSize={0} color={fontColor}>
                Please read and agree to the <strong>{companyName}</strong>{" "}
                <Link fontSize={0} href={termsLink} color={linkFontColor}>
                  Terms and Conditions
                </Link>{" "}
                and{" "}
                <Link fontSize={0} href={privacyLink} color={linkFontColor}>
                  Privacy Policy
                </Link>
                before proceeding.
              </Paragraph>
            )}

            <Button
              width={"100%"}
              backgroundColor={buttonColor}
              color={buttonFontColor}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      </Flex>
    </PreviewContainer>
  );
};

export default SplashScreen;
