import { Flex } from "../components/Flex";
import { Box } from "../components/Box";
import { Image } from "../components/Image";
import { Input } from "../components/Input";
import { H1 } from "../components/Heading";
import { Paragraph } from "../components/Paragraph";
import { Button } from "../components/Button";
import { Link, Navigate, useNavigate } from "react-router-dom";
import loginSvg from "../assets/login.svg";
import tillerVerifySvg from "../assets/verify-tiller.svg";
import { useAuth } from "../context/AuthContext";
import { ROUTE } from "../routes";
import { FeatureFlagKey, isEnabled } from "../feature-flags";
import { Form, Formik } from "formik";
import { loginValidation } from "../utils/validationSchema";

const Login = () => {
  const { isLoggedIn } = useAuth();
  const { login } = useAuth();
  const { userDetails, isLoadingUserDetails } = useAuth();

  let navigate = useNavigate();

  const onForgotPasswordClick = () => {
    navigate(ROUTE.RESET_PASSWORD);
  };

  const handleLogin = async (email, password) => {
    await login.call(email, password);
  };
  if (isLoggedIn && !isLoadingUserDetails) {
    if (userDetails?.two_factor_enabled) {
      return <Navigate to={ROUTE.LOGIN_2FA} replace />;
    } else if(userDetails?.two_factor_enabled === false){
      return <Navigate to={ROUTE.APPLICATIONS} replace />;
    } else {
      return <Navigate to={ROUTE.SETUP2FA} replace />;
    }
  }

  return (
    <Flex height="100vh">
      <Flex
        width="900px"
        justifyContent="space-between"
        flexDirection="column"
        p="60px"
        overflowY="auto"
      >
        <Image
          src={tillerVerifySvg}
          alt="tiller verify logo"
          height="200px"
          width="160px"
        />
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={loginValidation}
          onSubmit={({ email, password }) => {
            handleLogin(email, password);
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Box>
                <H1 color="darkBlue">Welcome Back</H1>
                <Paragraph color="darkBlue">
                  Sign into your account to continue
                </Paragraph>
                <Input
                  onChange={props.handleChange}
                  value={props.values.email}
                  placeholder="Enter your email address"
                  mt="4"
                  label="Email Address"
                  name="email"
                  hasError={Boolean(props.errors?.email)}
                  errorMessage={props.errors?.email}
                  isRequired
                />
                <Input
                  onChange={props.handleChange}
                  value={props.values.password}
                  type="password"
                  placeholder="Enter your password"
                  mt="2"
                  label="Password"
                  name="password"
                  hasError={Boolean(props.errors?.password)}
                  errorMessage={props.errors?.password}
                  isRequired
                />
                <Box height="24px" my="2">
                  {login.errors?.detail && (
                    <Paragraph my="0px" fontSize="1" color="red.primary">
                      {login.errors?.detail}
                    </Paragraph>
                  )}
                </Box>
              </Box>
              <Box>
                {/* Update the isDisabled prop based on areFieldsFilled */}
                <Button
                  type="submit"
                  isDisabled={
                    !props.dirty || // Disable if the form is not touched
                    (props.dirty && !props.isValid) || // Disable if the form is dirty but not valid
                    login.isLoading
                  }
                  mt="2"
                  size="large"
                  width="100%"
                >
                  Sign In
                </Button>
                {isEnabled(FeatureFlagKey.RESET_PASSWORD) && (
                  <Button
                    variant="text"
                    onClick={onForgotPasswordClick}
                    size="large"
                    mt="2"
                    width="100%"
                  >
                    Forgot password?
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
        <Link to={ROUTE.SIGN_UP}>
          <Button variant="text" size="large" mt="2" width="100%">
            No account? Sign up
          </Button>
        </Link>
      </Flex>
      <Flex
        bg={"background"}
        display={{ _: "none", md: "flex" }}
        height="100vh"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={loginSvg}
          alt="login image"
          display="block"
          width="55%"
          height="60%"
        />
      </Flex>
    </Flex>
  );
};

export default Login;
