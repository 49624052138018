import { Flex } from "../components/Flex";
import { Box } from "../components/Box";
import { Image } from "../components/Image";
import { Input } from "../components/Input";
import { H2 } from "../components/Heading";
import { Paragraph } from "../components/Paragraph";
import { Button } from "../components/Button";
import { useNavigate } from "react-router-dom";
import loginSvg from "../assets/login.svg";
import tillerVerifySvg from "../assets/verify-tiller.svg";
import { ROUTE } from "../routes";
import { Form, Formik } from "formik";
import checkmark from "../assets/checkmark-green.svg";
import { codeValidationSchema } from "../utils/validationSchema";
import { useState } from "react";
import { useLoginOTP } from "../data/useLoginOTP";
import { showError } from "../utils/error-handling";

const Login2FA = () => {
  const [doubleClick, setDoubleClick] = useState(false);
  const navigate = useNavigate();

  const { mutate: loginotp } = useLoginOTP();

  const handleSubmit = (code, setFieldError) => {
    setDoubleClick(true);
    loginotp(code, {
      onSuccess: () => {
        setDoubleClick(false);
        navigate(ROUTE.APPLICATIONS);
      },

      onError: (err: any) => {
        setFieldError(
          "verificationCode",
          "The code entered is invalid or expired."
        );
        setDoubleClick(false);
        showError(
          err,
          "Problem with authenticator code, please generate new or try to recover account."
        );
      },
    });
  };
  return (
    <Flex height="100vh">
      <Flex width="900px" flexDirection="column" p="60px" overflowY="auto">
        <Image
          src={tillerVerifySvg}
          alt="tiller verify logo"
          height="200px"
          width="160px"
        />

        <H2>Authenticator Code</H2>
        <Paragraph>
          Use your code generator app to generate a code and enter it below.
        </Paragraph>
        <Formik
          initialValues={{ verificationCode: "" }}
          validationSchema={codeValidationSchema}
          onSubmit={(values, { setFieldError }) => {
            handleSubmit(values.verificationCode, setFieldError);
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Flex
                gap="6"
                alignItems={"center"}
                justifyContent={"space-around"}
              >
                <Input
                  width={"100%"}
                  onChange={props.handleChange}
                  value={props.values.verificationCode}
                  placeholder="Enter 6-digit code"
                  label="Code"
                  name="verificationCode"
                  max={6}
                  isRequired
                  hasError={Boolean(props.errors.verificationCode)}
                  errorMessage={props.errors.verificationCode}
                />
                {props?.values?.verificationCode?.length === 6 && (
                  <Box>
                    <Image
                      src={checkmark}
                      alt="Valid OTP"
                      width="24px"
                      height="24px"
                      marginTop={24}
                    />
                  </Box>
                )}
              </Flex>
              <Flex my={2} justifyContent={"end"}>
                <Button
                  type="submit"
                  isDisabled={
                    !props.dirty ||
                    (props.dirty && !props.isValid) ||
                    doubleClick
                  }
                  mt="2"
                  size="large"
                  width="100%"
                >
                  Sign In
                </Button>
              </Flex>

              <Paragraph>
                I no longer have access to my authenticator app and need to
                recover my account.
              </Paragraph>

              <Paragraph>
                <Button
                  p={0}
                  variant="blueText"
                  onClick={() =>
                    navigate(ROUTE.RECOVER_ACCOUNT, { replace: true })
                  }
                >
                  Recover my account?
                </Button>
              </Paragraph>
            </Form>
          )}
        </Formik>
      </Flex>
      <Flex
        bg={"background"}
        display={{ _: "none", md: "flex" }}
        height="100vh"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={loginSvg}
          alt="login image"
          display="block"
          width="55%"
          height="60%"
        />
      </Flex>
    </Flex>
  );
};

export default Login2FA;
