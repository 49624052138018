import { useState } from "react";
import { Button } from "../../Button";
import { Flex } from "../../Flex";
import { H2 } from "../../Heading";
import { Modal } from "../../Modal";
import { Paragraph } from "../../Paragraph";
import { GrayCard } from "../../Card";
import { Disable2FAModal } from "./Disable2FAModal";
import { queryClient } from "../../../queryClient";

export const Manage2FAModal = (props: any) => {
  const { isOpen, onRequestClose } = props;
  const [isDisable2FAModalOpen, setIsDisable2FAModalOpen] = useState(false);

  const handleDisable = () => {
    onRequestClose();
    setIsDisable2FAModalOpen(true);
  };

  return (
    <>
      <Modal width="700px" isOpen={isOpen} onClose={onRequestClose}>
        <H2>Manage 2 Factor Authentication</H2>

        <Paragraph>
          After successfully entering your password, you’ll be prompted to enter
          a one-time code each time you log in.
        </Paragraph>

        <GrayCard mx={"0px"}>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Paragraph>
              2 Factor Authentication is <strong>ON</strong>
            </Paragraph>

            <Button
              type="button"
              variant="blueTextWithBorder"
              onClick={handleDisable}
            >
              Turn Off
            </Button>
          </Flex>
        </GrayCard>

        <Flex mt={4} justifyContent={"end"}>
          <Button type="button" variant="primary" onClick={onRequestClose}>
            Done
          </Button>
        </Flex>
      </Modal>
      <Disable2FAModal
        isOpen={isDisable2FAModalOpen}
        onRequestClose={() => {
          setIsDisable2FAModalOpen(false);
          queryClient.invalidateQueries(["userDetails"]);
        }}
      />
    </>
  );
};
