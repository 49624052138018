import axios from "axios";
import { env } from "../utils/env";
import { useAuth } from "../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { form } from "../types/Forms";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

interface FormResponse {
    count: number;
    next: string | null;
    previous: string | null;
    results: form[];
  }

const fetch = (authHeader: string) => () =>
  axios.get<FormResponse>(
    `${env.verifyServiceUrl}individual/form/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useForms = () => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  return useQuery(["forms"], fetch(authHeader), {
    retry: false,
    select: (response) => response.data.results,
    onError: (err: any) => {
      if(err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
      if(err.request.status === 401) {
        navigate(ROUTE.LOGOUT);
      }
    }
  });
};
